@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: Montserrat, sans-serif;
}

.corporate-name {
  @apply font-bold text-2xl inline-block h-7;

  &.smaller {
    @apply h-6 text-xl;
  }
}

.corporate-slogan {
  @apply font-bold text-xs inline-block;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100vw;
}

.line {
  &.left {
    top: 50%;
    right: 0;

    &::after {
      top: 0;
      right: -15%;
      animation: toTheLeft 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }

    &.teal {
      &::after {
        background: linear-gradient(to left, rgba(82, 187, 192, 0) 0%, rgba(82, 187, 174, 0.5) 75%, #52BBAE 100%);
      }
    }

    &.yellow {
      &::after {
        background: linear-gradient(to left, rgba(82, 187, 192, 0) 0%, rgba(255, 210, 7, 0.5) 75%, #FFD207 100%);
      }
    }

    &.red {
      &::after {
        background: linear-gradient(to left, rgba(82, 187, 192, 0) 0%, rgba(232, 49, 138, 0.5) 50%, #E8318A 100%);
      }
    }
  }
}

.line {
  &.right {
    top: 50%;
    left: 0;

    &::after {
      top: 0;
      left: -15%;
      animation: toTheRight 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }

    &.teal {
      &::after {
        background: linear-gradient(to right, rgba(82, 187, 192, 0) 0%, rgba(82, 187, 174, 0.5) 75%, #52BBAE 100%);
      }
    }

    &.yellow {
      &::after {
        background: linear-gradient(to right, rgba(82, 187, 192, 0) 0%, rgba(255, 210, 7, 0.5) 75%, #FFD207 100%);
      }
    }

    &.red {
      &::after {
        background: linear-gradient(to right, rgba(82, 187, 192, 0) 0%, rgba(232, 49, 138, 0.5) 50%, #E8318A 100%);
      }
    }
  }
}

.line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(51, 65, 85, 1);
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 15vw;
  }


  &.delay-1 {
    &::after {
      animation-delay: 0s;
    }
  }

  &.delay-2 {
    &::after {
      animation-delay: 1.1s;
    }
  }

  &.delay-3 {
    &::after {
      animation-delay: 1.5s;
    }
  }

  &.delay-4 {
    &::after {
      animation-delay: 1.9s;
    }
  }

  &.delay-5 {
    &::after {
      animation-delay: 2.4s;
    }
  }

  &.delay-6 {
    &::after {
      animation-delay: 2.8s;
    }
  }

  &.delay-7 {
    &::after {
      animation-delay: 3.2s;
    }
  }

  &.delay-8 {
    &::after {
      animation-delay: 3.5s;
    }
  }

  &.delay-9 {
    &::after {
      animation-delay: 3.9s;
    }
  }

  &.delay-10 {
    &::after {
      animation-delay: 4.4s;
    }
  }

  &.delay-11 {
    &::after {
      animation-delay: 4.8s;
    }
  }

  &.delay-12 {
    &::after {
      animation-delay: 5.3s;
    }
  }

  &.speed-1 {
    &::after {
      animation-duration: 5s;
    }
  }

  &.speed-2 {
    &::after {
      animation-duration: 6s;
    }
  }

  &.speed-3 {
    &::after {
      animation-duration: 7s;
    }
  }

  &.speed-4 {
    &::after {
      animation-duration: 8s;
    }
  }

  &.speed-5 {
    &::after {
      animation-duration: 9s;
    }
  }

  &.speed-6 {
    &::after {
      animation-duration: 10s;
    }
  }
}

@keyframes toTheRight {
  0% {
    left: -15%;
  }
  100% {
    left: 110%;
  }
}


@keyframes toTheLeft {
  0% {
    right: -15%;
  }
  100% {
    right: 110%;
  }
}
